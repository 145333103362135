
require('../styles/detail.scss');

import Handorgel from 'handorgel';

(() => {
    let accordion;
    const addNextAccordions = (el, accordion) => {
        if (el && el.classList.contains('ce_accordion')) {
            const toggler = el.querySelector('.toggler');
            const element = el.querySelector('.accordion');

            if (!toggler || !element) {
                return;
            }

            toggler.className = 'handorgel__header';
            element.className = 'handorgel__content';

            addNextAccordions(el.nextElementSibling, accordion);

            accordion.insertBefore(element, accordion.firstChild);
            accordion.insertBefore(toggler, accordion.firstChild);

            el.remove();
        }
    };

    document.querySelectorAll('.ce_accordion').forEach((el) => {
        if (el.querySelector('.accordion')) {
            accordion = document.createElement('div');
            accordion.classList.add('handorgel');

            el.parentNode.insertBefore(accordion, el);

            addNextAccordions(el, accordion);

            //el.classList.add('handorgel__element');
            //accordion.insertBefore(el, accordion.firstChild);

            new Handorgel(accordion, {
                multiSelectable: true,
            });
        }
    });

    document.querySelectorAll('.ce_rsce_sources').forEach((sources) => {
        // Previous node should be ol/ul > li as last child
        if (!sources.previousElementSibling || !sources.previousElementSibling.lastElementChild) {
            return;
        }

        const el = sources.previousElementSibling.lastElementChild.lastElementChild;

        if (el && el.tagName.toUpperCase() === 'LI') {
            el.classList.add('no-border');
        }
    });
})();
